/* eslint-disable react/react-in-jsx-scope */
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { getFormattedCart, getUpdatedItems, getFormattedCartToWhatsapp } from "../../../functions";
import { event, itemsGoogleAnalytics } from "../../trakers/google";
import { osName } from "react-device-detect";
import { AppContext } from "../../context/AppContext";
import FlyCartItem from "./FlyCartItem";
import EmptyCart from "./EmptyCart";
import UPDATE_CART from "../../../mutations/update-cart";
import GET_CART from "../../../queries/get-cart";
import LoadingSpinnerCss from "../../LoadingSpinnerCss";

const FlyCartItemsContainer = () => {

	const [cart, setCart] = useContext(AppContext);
	const [requestError, setRequestError] = useState(null);

	// Get Cart Data.
	const { loading: loadCart, error: errorCart, data: dataCart, refetch } = useQuery(GET_CART, {
		fetchPolicy: "no-cache",
		onCompleted: () => {
			// Update cart in the localStorage.
			const updatedCart = getFormattedCart(dataCart);
			localStorage.setItem("classic-cart", JSON.stringify(updatedCart));
			// Update cart data in React Context.
			setCart(updatedCart);
		},
		onError: (error) => {
			if (error) {
				const errorMessage = error?.graphQLErrors?.[0]?.message ? error.graphQLErrors[0].message : "";
				if (errorMessage == "Expired token") {
					var cart = localStorage.getItem("classic-cart");
					if (cart) localStorage.setItem("classic-cart", null);
					localStorage.removeItem("woo-session");
					setRequestError("La sesión habia caducado, inténtelo de nuevo");
				} else setRequestError(errorMessage);
			}
		}
	});

	// Update Cart Mutation.
	const [updateCart, { data: updateCartResponse, loading: updateCartProcessing, error: updateCartError }] = useMutation(UPDATE_CART, {
		onCompleted: () => {
			let tmpCart = JSON.parse(localStorage.getItem("classic-cart"));
			setCart(tmpCart);
			refetch();
		},
		onError: (error) => {
			if (error) {
				const errorMessage = error?.graphQLErrors?.[0]?.message ? error.graphQLErrors[0].message : "";
				if (errorMessage == "Expired token") {
					var cart = localStorage.getItem("classic-cart");
					if (cart) localStorage.setItem("classic-cart", null);
					localStorage.removeItem("woo-session");
					setRequestError("La sesión habia caducado, inténtelo de nuevo");
				} else setRequestError(errorMessage);
			}
		}
	});

	/*
	 * Handle remove product click.
	 *
	 * @param {Object} event event
	 * @param {Integer} Product Id.
	 *
	 * @return {void}
	 */
	const handleRemoveProductClick = (event, cartKey, products) => {

		event.stopPropagation();
		if (products.length) {

			// By passing the newQty to 0 in updateCart Mutation, it will remove the item.
			const newQty = 0;
			const updatedItems = getUpdatedItems(products, newQty, cartKey);

			updateCart({
				variables: {
					input: {
						clientMutationId: v4(),
						items: updatedItems
					}
				},
			});

			let tmpCart = JSON.parse(localStorage.getItem("classic-cart"));
			setCart(tmpCart);
		}
	};

	const handleWhatsappClick = () => {

		if (!isEmpty(cart)) {
			//send to google analytics
			let numero = cart?.totalProductsPrice?.replace("$", "");
			let value = parseFloat(numero.replace(".", "").replace(",", ""));
			let itemsG = itemsGoogleAnalytics(cart);

			event("send_to_whatsapp", {
				"value": value,
				"currency": "COP",
				"items": itemsG
			});
		}
		const formatWhatsapp = getFormattedCartToWhatsapp(cart);
		window.location = formatWhatsapp;
	};

	const myOrder = () => {
		window.location = "/checkout/?step=information";
	};

	return (
		<div>
			{cart ? (
				<div className="classic-cart-wrapper flex flex-col justify-between">
					<div className={`${osName == "iOS" ? "scroll-container-ios" : "scroll-container"}`}>
						<div className="cart-products-drawer px-5">
							{cart?.products?.length && (
								cart.products.map((item) => (
									<div key={item.cartKey}>
										<FlyCartItem
											item={item}
											updateCartProcessing={updateCartProcessing}
											products={cart.products}
											handleRemoveProductClick={handleRemoveProductClick}
											updateCart={updateCart}
										/>
										<div className="mt-4"></div>
									</div>
								))
							)}
						</div>
					</div>
					{/*Cart Total*/}
					<div className="classic-cart-total-container w-screen sm:w-[504px] flex-col mx-auto p-4 justify-between h-1/3">
						<div className="flex justify-between">
							<div className="classic-cart-element-total text-base font-helvetica-text font-medium text-black">
								Subtotal
								{cart?.products?.length > 1 ?
									<span className="font-normal antialiased">{" (" + cart?.products?.length + " Productos)"}</span>
									:
									<span className="font-normal antialiased">{" (" + cart?.products?.length + " Producto)"}</span>
								}
							</div>
							<div className="classic-cart-element-amt text-base font-helvetica-text font-medium text-black antialiased">
								{("string" !== typeof cart?.totalProductsPrice) ? cart?.totalProductsPrice?.substring(0, cart?.totalProductsPrice?.length - 3) : cart?.totalProductsPrice}
							</div>
						</div>
						<button onClick={() => { if (!loadCart) myOrder(); }} className="bg-black hover:bg-black/80 text-white px-5 py-3 rounded-md w-full mt-5">
							{loadCart ? <LoadingSpinnerCss /> : <span className="classic-cart-checkout-txt font-helvetica-text font-medium text-sm antialiased h-5">Paga por la Página</span>}
						</button>
						<button className="bg-green-whatsapp hover:bg-green-whatsapp/80 text-white px-5 py-3 rounded-md w-full mt-3"
							onClick={() => { if (!loadCart) handleWhatsappClick(); }}>
							{loadCart ? <LoadingSpinnerCss /> : <span className="classic-cart-checkout-txt font-helvetica-text font-medium text-sm antialiased h-5">Paga por Whatsapp</span>}
						</button>
					</div>
					{/* Display Errors if any */}
					{requestError ? <div className="row classic-cart-total-container mt-5 text-red text-xs lg:text-lg font-helvetica-text font-normal antialiased"> {requestError} </div> : ""}
				</div>
			) : (
				<EmptyCart />
			)}
		</div>

	);
};

export default FlyCartItemsContainer;
